<template>
  <div class="page">
    <el-form
        ref="form"
        label-width="120px"
        :loading="form_loading"
        :model="form"
    >
      <el-form-item label="物流政策" style="margin-top: 30px">
        <el-radio-group size="small" v-model="form.value">
          <el-radio-button :label="0">全场包邮</el-radio-button>
          <el-radio-button :label="1">单一运费模板</el-radio-button>
          <el-radio-button :label="2">(用户自选)运费模板</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="form.value == 0">
        <span>当一笔订单里有2个以上物品，其中多个物品设置了自己的物流方案，另外一个物品选择跟随系统物流方案设置时，以最贵运费物品的运费进行征收。</span>
      </el-form-item>
      <el-form-item v-show="form.value == 1">
        <div>当用户订单同时匹配到多个运费模板,或订单里有多个物品额外设置了运费模板，应</div>
        <el-select
            v-model="form.config.choice_type"
            placeholder="请选择"
            size="small"
            style="width: 300px"
        >
          <el-option label="最大运费为准" value="0"></el-option>
          <el-option label="最小运费为准" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="form.value == 1">
        <div>当既有订单级别的运费模板，又有物品额外指定的运费模板时，最终费用应</div>
        <el-select
            v-model="conflict_choice1"
            placeholder="请选择"
            size="small"
            style="width: 300px"
        >
          <el-option label="最大运费为准" value="0"></el-option>
          <el-option label="最小运费为准" value="1"></el-option>
          <el-option label="两者之和处理" value="2"></el-option>
          <el-option label="忽略自选运费模板" value="3"></el-option>
          <el-option label="忽略商品物流费用" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="form.value == 2">
        <div>订单里有多个物品额外设置了运费模板，应</div>
        <el-select
            v-model="form.config.choice_type"
            placeholder="请选择"
            size="small"
            style="width: 300px"
        >
          <el-option label="最大运费为准" value="0"></el-option>
          <el-option label="最小运费为准" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="form.value == 2">
        <span>客户将在前端自行选择适合他的运费模板进行计费时，但该订单中某些物品又额外指定了运费模板，此时运费应按</span>
      </el-form-item>
      <el-form-item v-show="form.value == 2">
<!--        <div>当一笔订单里有2个以上物品，其中一个物品设置了自己的物流方案，另外一个物品选择跟随系统物流方案设置时，以另外一个物品的运费进行征收，此时按</div>-->
        <el-select
            v-model="conflict_choice2"
            placeholder="请选择"
            size="small"
            style="width: 300px"
        >
          <el-option label="最大运费为准" value="0"></el-option>
          <el-option label="最小运费为准" value="1"></el-option>
          <el-option label="两者之和处理" value="2"></el-option>
          <el-option label="忽略自选运费模板" value="3"></el-option>
          <el-option label="忽略商品物流费用" value="4"></el-option>
        </el-select>
        <span>计算</span>
      </el-form-item>
      <el-form-item label="附加规则">
        <div style="margin-top: 30px">如果产品没有设置长宽高，则默认体积为
          <el-input
              type="number"
              v-model="form.config.volume"
              size="small"
              style="width: 150px"
              placeholder="请输入体积"
          />
          cm³
        </div>
        <div>如果产品没有设置重量，则默认重量为
          <el-input
              type="number"
              v-model="form.config.weight"
              size="small"
              style="width: 150px"
              placeholder="请输入重量"
          />
          g
        </div>
        <div>一般产品会额外有包裹包装，针对该包裹包装材料，默认该包装重量为总重量的
          <el-input
              type="number"
              v-model="form.config.weight_percent"
              size="small"
              style="width: 100px"
              placeholder="请输入"
          />
          %，封顶重量
          <el-input
              type="number"
              v-model="form.config.max_weight"
              size="small"
              style="width: 100px"
              placeholder="请输入"
          />
          g；
<!--          默认包裹体积为总体积的-->
<!--          <el-input-->
<!--              type="number"-->
<!--              v-model="form.config.volume_percent"-->
<!--              size="small"-->
<!--              style="width: 100px"-->
<!--              placeholder="请输入"-->
<!--          />-->
<!--          %，封顶体积为-->
<!--          <el-input-->
<!--              type="number"-->
<!--              v-model="form.config.max_volume"-->
<!--              size="small"-->
<!--              style="width: 100px"-->
<!--              placeholder="请输入"-->
<!--          />-->
<!--          cm³-->
        </div>
        <div>
          体积重系数:
          <el-input
              type="number"
              v-model="form.config.package_weight"
              size="small"
              style="width: 150px"
              placeholder="5000"
          />
          (国际通用值:50000)
        </div>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="save" style="margin: 0 0 50px 50px">提 交</el-button>
  </div>
</template>

<script>
import {apiGetGlobalConfiguration, apiSetConfiguration} from "@/request/api";

export default {
  name: "setlogistics.vue",
  data() {
    return {
      form_loading: false,
      conflict_choice1: '',
      conflict_choice2: '',
      form: {
        value: 0,
        config: {
          choice_type: '',
          conflict_choice: '',
          volume: '',
          weight: '',
          weight_percent: '',
          max_weight: '',
          // max_volume: '',
          // volume_percent: '',
          package_weight: '',
        },
      },
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    //保存配置
    save() {
      if (this.form.value == 1) {
        this.form.config.conflict_choice = this.conflict_choice1
      } else if (this.form.value == 2) {
        this.form.config.conflict_choice = this.conflict_choice2
      } else {
        this.form.config.conflict_choice = ''
      }
      apiSetConfiguration(this.form).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          /*this.form = {
            value: 0,
            config: {
              choice_type: '',
              conflict_choice: '',
              volume: '',
              weight: '',
              weight_percent: '',
              max_weight: '',
              max_volume: '',
              volume_percent: '',
              package_weight: '',
            },
          }*/
          this.fetchData()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    fetchData() {
      apiGetGlobalConfiguration().then(res => {
        if (res.code == 200) {
          this.form = {
            id: res.data.id,
            value: res.data.value,
            config: res.data.value_conf,
          }
          if (res.data.value == 1) {
            this.conflict_choice1 = res.data.value_conf.conflict_choice
          } else if (res.data.value == 2) {
            this.conflict_choice2 = res.data.value_conf.conflict_choice
          } else {
            this.conflict_choice1 = ''
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
  /*margin-bottom: 80px;*/
}
</style>